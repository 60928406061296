import { useState } from 'react';
import SearchBox from './search';
import AuthTracker from './authTracker';
import axios from 'axios'
import { Form, Button, Container, Row, Col, Dropdown } from 'react-bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/themes/lara-light-indigo/theme.css'; // Or any other PrimeReact theme
import 'primereact/resources/primereact.min.css'; // PrimeReact core CSS
import 'primeicons/primeicons.css'; // PrimeIcons CSS
import { Panel } from 'primereact/panel';
import React, { useEffect } from "react";

function Analyze() {
    AuthTracker()

    const [isVisible, setIsVisible] = useState(true);

    const [DTtableData, setDTtableData] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');
    const [searchSQL, setSearchSQL] = useState('');
    const [dbconnections, setDbconnections] = useState([]);
    const [databases, setDatabases] = useState([]);
    const [tables, setTables] = useState([]);
    const [columns, setColumns] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');

    async function onVisualizeClick() {
        const postData = {
            query: document.getElementById('searchSQL').value,
            dbConnId: document.getElementById('selectDBConn').value,
            dbType: document.getElementById('source').value
        };

        //console.log(postData);

        try {
            const response = await axios.post('/api/analyze/getdata', postData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const sqlData = response.data;

            //console.log(sqlData);

            // Extract the first key dynamically
            const tableKey = Object.keys(sqlData)[1];
            //console.log(tableKey);
            const data11 = sqlData[tableKey];

            // Extract the dynamic key
            const dynamicKey = Object.keys(data11)[0];

            // Access the data array using the dynamic key
            const dataArray = data11[dynamicKey];

            // Check if the array is valid and not empty
            if (dataArray && dataArray.length > 0) {
                // Get the keys of the first object in the array
                const keys11 = Object.keys(dataArray[0]);
                console.log("Keys:", keys11);

                const foo = keys11.map(key => ({
                    field: key,
                    header: key,
                }));

                console.log(foo);
                setColumns(foo);

                // Set the table data
                // Transform data from objects to arrays of values (remove keys)
                //const transformedData = dataArray.map(row => Object.values(row)); 
                setDTtableData(dataArray);
                console.log(dataArray)
            } else {
                console.log("No data available in the array.");
            }
        } catch (error) {
            console.error('Error making request:', error);
        }
    }

    async function onSearchClick() {
        const postData = {
            input: searchTerm,
            tableName: document.getElementById('selectDBTable').value,
            dbConnId: document.getElementById('selectDBConn').value,
            dbType: document.getElementById('source').value
        };

        console.log(postData);

        try {
            const response = await axios.post('/api/analyze/texttosql', postData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const sqlData = await response.data;
            console.log(sqlData);
            setSearchSQL(sqlData.data);
        } catch (error) {
            console.error('Error making request:', error);
        }
    }


    const handleSourceChange = async (event) => {
        const selectedValue = event.target.value;

        if (selectedValue.toLowerCase() === "SQLServer".toLowerCase()) {
            axios.get('/api/analyze/getsqlserverconnections')
                .then(response => {
                    // Check if the request was successful
                    if (response.status === 200) {
                        const data = response.data.data; // Access the data
                        setDbconnections(data); // Set the fetched databases into state
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    };

    const handleDBConnectionChange = async (event) => {
        const selectedValue = event.target.value;

        if (selectedValue !== "") {
            axios.get(`/api/analyze/getdatabases?dbConnID=${selectedValue}`)
                .then(response => {
                    // Check if the request was successful
                    if (response.status === 200) {
                        const data = response.data.data; // Access the data
                        const dynamicKey = Object.keys(data)[0];  // Get the first key, which is dynamic
                        const tableData = data[dynamicKey];       // Access the data using the dynamic key
                        console.log(tableData);
                        setDatabases(tableData); // Set the fetched databases into state
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    };

    const handleDBChange = async (event) => {
        const selectedValue = event.target.value;

        if (selectedValue !== "") {
            const selectDBConnIdVal = document.getElementById('selectDBConn').value;
            console.log(selectDBConnIdVal);
            axios.get(`/api/analyze/getdatabasetables?dbConnID=${selectDBConnIdVal}&dbName=${selectedValue}`)
                .then(response => {
                    // Check if the request was successful
                    if (response.status === 200) {
                        const data = response.data.data; // Access the data
                        const dynamicKey = Object.keys(data)[0];  // Get the first key, which is dynamic
                        const tableData = data[dynamicKey];       // Access the data using the dynamic key
                        console.log(tableData);
                        setTables(tableData);
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    };

    const handleTableChange = async (event) => {
        const selectedValue = event.target.value;
    };

    return (
        <div>
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <h1>Analyze</h1>
                    </Col>
                </Row>
                <Panel header="Extract Data From Source">
                    <Row className="mb-2">
                        <Col md={3}>
                            <label htmlFor="source"><b>Select source</b></label>
                            <Form.Select id="source" onChange={handleSourceChange}>
                                <option value="">--Select--</option>
                                <option value="SQLServer">SQL Server</option>
                                <option value="MySQL">My SQL</option>
                                <option value="SnowFlake">SnowFlake</option>
                                <option value="BigQuery">BigQuery</option>
                            </Form.Select>
                        </Col>
                        <Col md={3}>
                            <label htmlFor="selectDBConn"><b>Select Connection</b></label>
                            <Form.Select id="selectDBConn" onChange={handleDBConnectionChange}>
                                <option value="">--Select--</option>
                                {dbconnections.map((conn) => (
                                    <option key={conn.id} value={conn.id}>
                                        {conn.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col md={3}>
                            <label htmlFor="selectDB"><b>Select Database</b></label>
                            <Form.Select id="selectDB" onChange={handleDBChange}>
                                <option value="">--Select--</option>
                                {databases.map((db) => (
                                    <option key={db.name} value={db.name}>
                                        {db.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col md={3}>
                            <label htmlFor="selectDBTable"><b>Select Table</b></label>
                            <Form.Select id="selectDBTable" onChange={handleTableChange}>
                                <option value="">--Select--</option>
                                {tables.map((tbl) => (
                                    <option key={`${tbl.TABLE_NAME}`} value={`${tbl.TABLE_NAME}`}>
                                        {`${tbl.TABLE_NAME}`}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                    {/* {showtxtTableMetadata && (
                )} */}
                    <Row className="mb-2">
                        <Col md={6}>
                            <label htmlFor="search"><b>Ask your question</b></label>
                            <Form.Control as="textarea" rows={5} id="search" onChange={(e) => setSearchTerm(e.target.value)} placeholder="Use AI to generate the SQL. Ask a question in your natural language. Example: List the employees who work out of the Seattle office." />
                        </Col>
                        <Col md={6}>
                            <label htmlFor="searchSQL"><b>SQL</b></label>
                            <Form.Control as="textarea" rows={5} id="searchSQL" value={searchSQL} onChange={(e) => setSearchSQL(e.target.value)} placeholder="SQL generated using the AI assistant on the left would be shown over here. You can also write SQL directly if you do not want to use the AI assistant." />
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={6}>
                            <Button variant="primary" onClick={onSearchClick}>Generate SQL using AI</Button>
                        </Col>
                        <Col md={6}>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    What next?
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item /*href="#/action-1"*/ onClick={onVisualizeClick}>Visualize and AI Analyze the data</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Create AI Data Quality Alerts</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Panel>
                <br/>
                <Row>
                    <Panel header="View/Explore The Extracted Data">
                        <Col md={12}>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-search"></i>
                                </span>
                                <InputText
                                    type="search"
                                    placeholder="Search..."
                                    onInput={(e) => setGlobalFilter(e.target.value)}
                                />
                            </div>
                            <DataTable value={DTtableData} size='Normal'
                                paginator rows={10} tableStyle={{ minWidth: '50rem' }}
                                globalFilter={globalFilter}>
                                {columns.map((col, i) => (
                                    <Column key={col.field} field={col.field} header={col.header} sortable={true}
                                    />
                                ))}
                            </DataTable>
                        </Col>
                    </Panel>
                </Row>
            </Container>
        </div>
    );
}

export default Analyze;